 





import theme__set_colors from "../utils/theme__set_colors"
import theme__display_button from "../components/theme__display_button"

const ModuleTextcontentcolumns = ({data}) => {

    const text_row_class = data.text_columns_count === 1 ? "row--column align-center" : "justify-center"
    const text_col_class = data.text_columns_count === 1 ? "col col--content" : "col col-1 col-sm-1-2 col-lg-1-" + data.text_columns_count;

    const headingclass = data.text_columns_count > 3 ? "heading-reg" : "";
    const textclass = data.text_columns_count > 3 ? "text-sml" : "";

    return (
        <section className={`module module--text-content-columns mt mb ${theme__set_colors(data)}`}>
          <div className="module__bd bd">
            <div className={`row row--mb ${text_row_class}`}>
                {data.text_columns ? data.text_columns.map(function(item, index) {
                    return (
                        <div key={index} className={`effect effect--default ${text_col_class}`}>
                            {item.heading ? <h3 className={headingclass}>{item.heading}</h3> : ""}
                            <div className={`contentarea  ${textclass}`} dangerouslySetInnerHTML={{__html: item.textarea}}></div>
                            { item.button ? theme__display_button(item.button, 'btn btn--primary') : ""}
                        </div> 
                    )
                }) : "" }
            </div> 
          </div>
        </section>
    )
}

export default ModuleTextcontentcolumns