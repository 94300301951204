
    const theme__display_button = (link, classes = false) => {
        return (
		<a className={classes} 
            href={link.url} 
            target={link.target}>
            {link.title}
        </a>

        )
    }
    
    export default theme__display_button