import ModuleLine from "../modules/module_line"
import ModuleLineheading from "../modules/module_lineheading"
import ModuleSectionheading from "../modules/module_seactionheading"
import ModuleTextcontentcolumns from "../modules/module_textcontentcolumns"

const Build = ({layout, data}) => {
        return (
        <div>
         { layout === 'module_sectionheading' ? <ModuleSectionheading data={data} /> : '' }
         { layout === 'module_line' ? <ModuleLine data={data} /> : '' }
         { layout === 'module_lineheading' ? <ModuleLineheading data={data} /> : '' }
         { layout === 'module_textcontent_columns' ? <ModuleTextcontentcolumns data={data} /> : '' }
        </div>
        
    )
}

export default Build