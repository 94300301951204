import theme__set_colors from "../utils/theme__set_colors"
import theme__display_button from "../components/theme__display_button"

const ModuleSectionheading = ({data}) => {
    return (
        <section className={`module module--section-heading section-heading mt mb text-center ${theme__set_colors(data)}`}>
            <div className="module__bd bd effect effect--default">
                <h4 className="section-heading__pre_heading heading-sml color-accent">{data.pre_heading}</h4>
                <div className="row justify-center">
                <div className="col col--content col--content--wide">
                    <h3 className="section-heading__heading heading-large">{data.heading}</h3>
                    <div className="section-heading__textarea">{data.textarea}</div>
                    <div className="section-heading__cta mt--em">
                        { theme__display_button(data.primary_button, 'btn btn--primary')}
                        { theme__display_button(data.secondary_button, 'btn btn--secondary')}
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default ModuleSectionheading