    const theme__set_colors_bg = ({bg_colour, with_padding}) => {
		let text = ''
		let classes = ''
		if(bg_colour && bg_colour.length !== 0 && bg_colour !== 'none') {
			if (bg_colour === 'black') {
				text = 'color-white'
			} else {
				text = ''
			}
			if (with_padding) {
				classes = 'pt pb '
			}
			classes = classes + ' module--bg bg-' +bg_colour+''+text
		}
		return classes ?? ''
    }
    
    export default theme__set_colors_bg