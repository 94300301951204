    import theme__set_colors_bg from "./theme__set_colors_bg"
    import theme__set_colors_accent from "./theme__set_colors_accent"
    
    const theme__set_colors = (module, with_padding) => {
        const bg_colour = (module && module.bg_colour) ? module.bg_colour : false
        const accent_colour = (module && module.accent_colour) ? module.accent_colour : false
        const bg_classes = theme__set_colors_bg(bg_colour, with_padding)
        const accent_classes = theme__set_colors_accent(accent_colour)
        return bg_classes+' ' + accent_classes
    }
    
    export default theme__set_colors