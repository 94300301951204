import theme__set_colors from "../utils/theme__set_colors"

const ModuleLine = ({data}) => {
    return (
        <section className={`module module--line mt mb ${theme__set_colors(data)}`}>
            <div className="module__bd bd effect effect--default">
                <div className="line"></div>
            </div>
        </section>
    )
}

export default ModuleLine