import React from 'react';
import './App.css';
  
import Build from './components/build'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    function geUrl(link) {
      const l = link.link;
      const u = window.location.href + '/'
      const u2 = u.replace(/\/\/$/, '/');
      const lr = u2.replace('https://joespace.co.uk/', 'https://cms.joespace.co.uk/')
          const lr2 = l.replace(/\/\/$/, '');
          return lr == lr2;
    }
    fetch("https://cms.joespace.co.uk/wp-json/wp/v2/pages")
      .then(res => res.json())
      .then(
        (result) => {
          const res = result.find(geUrl)
          console.log(res)
          this.setState({
            isLoaded: true,
            items: res['acf']['content_modules']
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return false;
    } else {
      return (
        <ul>
          {items.map((item, index) => (
            <Build key={index} layout={item.acf_fc_layout} data={item} />    
          ))}
        </ul>
      );
    }
  }
}

export default App;
