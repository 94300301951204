import theme__set_colors from "../utils/theme__set_colors"

const ModuleLineheading = ({data}) => {
    return (
        <section className={`module module--line-heading line-heading mt mb ${theme__set_colors(data)}`}>
            <div className="module__bd bd effect effect--default">
                <h4 className="line-heading__heading o-33 heading-sml">{data.heading}</h4>
            </div>
        </section>
    )
}

export default ModuleLineheading